import i18n from '@/i18n'
import { mapState } from 'vuex'
import Lset from 'lodash/set'
import Lmerge from 'lodash/merge'

import CrudTable from '@/lib/uncrudtable/components/CrudTable'
import apiClient from '@/lib/unlogin/store/apiclient'
import { createNotification } from '@/lib/unnotificationsqueue'

import { RESPONSE_LEVEL } from '@/variables'
import Layout from '@/apps/core/components/Layout'
import sections from '../defaultNavigationDrawerSections'
import { flattenFormProps } from '@/apps/core/helpers/utils'
import { successNotify } from '@/apps/core/helpers/successNotify'

export default {
  name: 'PromoKbdValidationList',
  components: {
    Layout,
    CrudTable
  },
  data () {
    return {
      title: i18n.tc('promoKbdValidation.sectionName'),
      sections: sections,
      customOptions: { 'sortBy': ['id'], 'sortDesc': [true] },
      storeModule: 'promoKbdValidation'
    }
  },
  computed: {
    ...mapState({
      crudTableConfig: function (state) { return state[this.storeModule].crudTableConfig },
      loading: function (state) { return state[this.storeModule].loading }
    })
  },
  methods: {
    Lset,
    /**
   * Event handler when modifying a CrudTable's editable field. Redirects it to respective patch endpoint
   * @param value {string, number} new value
   * @param header {string} field edited
   * @param item {object} full instance of modified row
   * @returns {Promise<void>}
   */
    async onModifyField ({ value, header, item }) {
      let nestedField = header.split('.')
      if (nestedField.length === 1 || (nestedField.length === 2 && nestedField[0] === 'props')) {
        await this.onModifyDetachment(header, value, item)
      }
    },
    /**
   * Called when a Detachment element is modified: calls Detachment's patch endpoint
   * @param header same as onModifyField
   * @param value same as onModifyField
   * @param item same as onModifyField
   * @returns {Promise<void>}
   */
    async onModifyDetachment (header, value, item) {
      let previousValue = this.Lget(item, header)
      try {
        if (header === 'campaign_status') {
          const status = value
          const idCampaign = item.promo.campaign.id
          await this.changeCampaignStatus(status, idCampaign)
        } else {
          let detachmentId = item.id
          let body = flattenFormProps(Lmerge(item, Lset({}, header, value)))
          body.promo = body.promo.id
          body.props = item.props
          body.props.status = value
          body.topic = body.topic.id
          await apiClient.patch(`/promo-detachments/${detachmentId}/`, body)
          await this.$store.dispatch('addNotification', createNotification(this.$t('dialogs.savedNewValues'), RESPONSE_LEVEL.SUCCESS))
        }
      } catch (error) {
        console.log(error)
        this.Lset(item, header, previousValue)
      }
    },

    async changeCampaignStatus (status, campaignId) {
      try {
        const body = {
          action_name: `mark_status_as_${status}`,
          filters: {},
          list_ids: [campaignId]
        }
        await successNotify(apiClient.post(`/campaign-actions/`, body))
      } catch (error) {
        console.error(error)
      }
    },

    /**
   * Displays error message
   * @param error
   */
    handleError (error) {
      let errorData = (error.response && error.response.data) || {}
      let level = errorData.level || RESPONSE_LEVEL.ERROR
      let message = errorData.message || this.$t('general.error')
      this.$store.dispatch('addNotification', createNotification(message, level))
    }
  }
}
