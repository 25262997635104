import { render, staticRenderFns } from "./PromoKbdValidationList.html?vue&type=template&id=ca96e4be&scoped=true&"
import script from "./PromoKbdValidationList.component.js?vue&type=script&lang=js&"
export * from "./PromoKbdValidationList.component.js?vue&type=script&lang=js&"
import style0 from "./PromoKbdValidationList.scss?vue&type=style&index=0&id=ca96e4be&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ca96e4be",
  null
  
)

export default component.exports